import request from "@/utils/request";

export function roleOptionList(params) {
  return request({ url: `/roles/options/`, method: "get", params });
}

export function crossingOptionList(params) {
  return request({ url: `/crossings/options/`, method: "get", params });
}

export function departmentOptionList(params) {
  return request({ url: `/departments/options/`, method: "get", params });
}

export function ursaOptionList(params) {
  return request({ url: `/ursas/options/`, method: "get", params });
}

export function airportOptionList(params) {
  return request({ url: `/airports/options/`, method: "get", params });
}

export function destinationLocationOptionList(params) {
  return request({ url: `/destination_locations/options/`, method: "get", params });
}

export function countryOptionList(params) {
  return request({ url: `/countries/options/`, method: "get", params });
}

export function residentialOptionList(params) {
  return request({ url: `/residentials/options/`, method: "get", params });
}

export function postalCodeOptionList(params) {
  return request({ url: `/postal_codes/options/`, method: "get", params });
}

export function sensitiveOptionList(params) {
  return request({ url: `/sensitives/options/`, method: "get", params });
}

export function packingCodeOptionList(params) {
  return request({ url: `/packing_codes/options/`, method: "get", params });
}

export function sortingRuleOptionList(params) {
  return request({ url: `/sorting_rules/options/`, method: "get", params });
}
